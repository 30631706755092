import React from 'react'
import { graphql, Link } from 'gatsby'
// import Bio from "../components/bio"
import Layout from '../components/layout'
import SEO from '../components/seo'
import { rhythm, scale } from '../utils/typography'

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date.toLocaleString()}
          </p>
        </header>
        <section>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
          <div>
            <span role='img' aria-label='Rocket Face Emoji'>
              🚀
            </span>
            &nbsp; Если узнал из статьи что-то полезное, ставь лайк и
            подписывайся на наш{' '}
            <a
              href='https://t.me/wcademy'
              rel='nofollow noopener noreferrer'
              target='_blank'
            >
              канал в Телеграм
            </a>{' '}
            или{' '}
            <a
              href='https://vk.com/wcademy'
              rel='nofollow noopener noreferrer'
              target='_blank'
            >
              группу ВК
            </a>
            . Обсудить статью можно в нашем{' '}
            <a
              href='https://t.me/wcademy_chat'
              rel='nofollow noopener noreferrer'
              target='_blank'
            >
              уютном чатике
            </a>
            &nbsp;
            <span role='img' aria-label='Smirking Face Emoji'>
              😏
            </span>
          </div>
        </section>

        <hr
          style={{
            marginBottom: rhythm(1),
            marginTop: rhythm(1),
          }}
        />
        <footer>{/* <Bio /> */}</footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel='prev'>
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel='next'>
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
